@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

* {
  margin: 0;
}

body {
  font-family: 'PT Serif', serif;
  background-color: #fffefd;
  overflow-x: hidden;
}

a:hover {
  cursor: pointer;
  text-decoration: none;
}

a:active {
  cursor: default;
  text-decoration: none;
}

::selection {
  color: #fff;
  background: #a8cbb1;
  color: #000;
}

.App {
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  overflow-x: hidden;
}

.page-container
{
  display: flex;
  flex-direction: column;
}

/* Titlescreen.js component */

.titlescreen {
  position: relative;
  display: grid;
  place-items: center;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  margin-bottom: 100px;
  overflow: hidden;
}

.titlescreen-title h2 {
  margin: 0 auto;
  text-align: center;
  font-size: 8vmax;
  font-weight: 400;
}
.titlescreen-title h2:nth-of-type(2) {
  color: #005718;
}

.titlescreen-images {
  z-index: -1;
  position: absolute;
  display: grid;
  align-items: center;
  width: 80vw;
  left: 10vw;
  height: 100vh;
  opacity: .4;
  pointer-events: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.titlescreen-images .set {
  position: absolute;
  width: 25vmin;
  left: 50%;
  margin-left: -12.5vmin;
  aspect-ratio: 1 / 1;
  padding: .5rem;
}
.titlescreen-images img {
  position: absolute;
  width: 100%;
}

.titlescreen-images .set img {
  border-radius: 1vmin;
  filter:  brightness(1.3) contrast(.7);
  -o-object-fit: cover;
  object-fit: cover;
}
.titlescreen-images .set > img:nth-of-type(1) {
  filter:  brightness(1.8) contrast(.6) opacity(.45);
}
.titlescreen-images img.portrait {
  aspect-ratio: 5 / 7;
}
.titlescreen-images img.landscape {
  aspect-ratio: 7 / 5;
}
.titlescreen-images .set:nth-of-type(1) {
  transform: translateX(-200%) translateX(25vmin) translateY(-130%);
}
.titlescreen-images .set:nth-of-type(1) > img:nth-of-type(2) {
  width: 90%;
}
.titlescreen-images .set:nth-of-type(1) > img:nth-of-type(1) {
  transform: translateX(-50%) translateY(50%);
}
.titlescreen-images .set:nth-of-type(2) {
  transform: translateX(270%) translateX(-25vmin) translateY(-90%);
}
.titlescreen-images .set:nth-of-type(2) > img:nth-of-type(1) {
  width: 80%;
  transform: translateX(-50%) translateY(-25%);
}
.titlescreen-images .set:nth-of-type(3) {
  transform: translateX(-285%) translateX(25vmin) translateY(80%);
}
.titlescreen-images .set:nth-of-type(3) > img:nth-of-type(2) {
  width: 80%;
}
.titlescreen-images .set:nth-of-type(3) > img:nth-of-type(1) {
  transform: translateX(50%) translateY(60%);
}
.titlescreen-images .set:nth-of-type(4) {
  transform: translateX(250%) translateX(-25vmin) translateY(85%);
}
.titlescreen-images .set:nth-of-type(4) > img:nth-of-type(2) {
  width: 80%;
}
.titlescreen-images .set:nth-of-type(4) > img:nth-of-type(1) {
  transform: translateX(-50%) translateY(80%);
}

/* Subheader.js component */

.subheader {
  position: relative;
  width: 100vw;
  height: 450px;
  filter: saturate(0.8);
}
.subheader .title-block {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: inset 200px -150px 200px rgba(255,255,255,.8);
  padding-left: 10%;
}
.subheader .title-block h2 {
  margin: 0;
}
.subheader .title-block h2:nth-of-type(2) {
  margin-bottom: 25px;
}
.subheader img {
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

/* Icons.js component */

.icons-block {
  display: flex;
  width: 100vw;
  background-color: #f7f6f4;
  padding-bottom: 35px;
}
.icons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 80%;
  margin-left: 10%;
  height: 100%;
}
.icon {
  margin: 0;
  display: flex;
  flex-direction: column;
  opacity: .2;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}
.icon img {
  height: 250px;
}
.icon p {
  margin: 0 auto;
  font-size: 20px;
  font-weight: 400;
  color: #000;
  white-space: nowrap;
}


/* Contactcard.js component */

.contactcard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 100%;
  max-width: 600px;
  height: 300px;
  background-color: #eee;
  border-radius: 2vmin;
}
.contactcard > .info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.contactcard .column {
  display: flex;
  flex-direction: column;
  margin: 0;
}
.contactcard p {
  margin: 20px;
  font-size: 16px;
  font-weight: 800;
  display: inline-block;
}
.contactcard .column:nth-of-type(1) p {
  margin-left: 40px;
}
.contactcard .column:nth-of-type(2) p {
  font-weight: 400;
}
.contactcard .column p a {
  text-decoration: underline;
  color: #000 !important;
}
.contactcard .column p a:hover {
  opacity: 0.6;
}
.contactcard .column p a:active {
  opacity: 1;
  text-decoration: none;
}
.contactcard .export-button {
  position: absolute;
  right: 20px;
  bottom: 20px;
  padding: 8px;
  padding-left: 15px;
  padding-right: 40px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 800;
  text-decoration: none;
  color: #005718;
  background-color: rgba(0,0,0,.05);
  background-image: url('https://heritage-ontzorgt.nl/img/export-icon.png');
  background-size: 20px auto;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 10px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.contactcard .export-button:hover {
  background-color: rgba(0,0,0,.07);
}
.contactcard .export-button:active {
  background-color: rgba(0,0,0,.1);
}
@media (max-width: 600px) {
  .contactcard .export-button {
    position: static;
    margin-top: 20px;
  }
}
