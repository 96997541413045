
/* Footer.js component */

footer {
  position: relative;
  display: flex;
  flex: auto;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 45px;
  padding-bottom: 100px;
  min-height: 400px;
  margin-top: 50px;
  background-color: #f7f6f4;
}
footer .col {
  margin: 0;
}
footer .col h2 {
  color: #005718;
  font-size: calc(20px + 1vmax);
  font-weight: 400;
  white-space: nowrap;
  margin-bottom: 10px;
}
footer .col a {
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  display: block;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #000000;
  text-decoration: none;
}
footer .col a:hover {
  text-decoration: underline;
}
footer .col a:active {
  text-decoration: none;
}
.footer-logo {
  position: absolute;
  width: 120px;
  bottom: 25px;
  right: 50%;
  margin-right: -60px;
  opacity: .5;
  filter: grayscale(100%);
  -webkit-transition: .3s ease;
  transition: .3s ease;
}
@media (max-width: 1000px) {
  footer {
    flex-direction: column;
  }
  footer .col {
    margin-bottom: 20px;
  }
  footer .col a {
    line-height: 32px;
  }
  .footer-logo {
    right: calc(10% + 80px)
  }
}
