
/* Header.js component */

header {
  z-index: 2;
  position: fixed;
  width: 100vw;
  max-width: 100%;
  height: 70px;
  background-color: rgba(255,255,255,.75);
  backdrop-filter: blur(7px);
}

.header-container {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
}

.header-logo {
  z-index: 0;
  height: 50px;
}

.menu {
  z-index: 3;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 13px;
  height: 70px;
  padding: 0;
  list-style-type: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: .4s ease;
  transition: .4s ease;
  opacity: 1;
  pointer-events: auto;
}

.menu > li > a {
  text-decoration: none;
  display: block;
  padding: 0 7px;
  font-size: 15px;
  line-height: 70px;
  font-weight: 400;
  position: relative;
  z-index: 1;
  text-align: center;
  white-space: nowrap;
  color: #000;
}
.menu > li > a:hover {
  background-color: rgba(200,200,200,.2);
}
.menu > li > a:active {
  text-decoration: none;
  background-color: rgba(200,200,200,.3);
}

/* Hamburger */

#hamburger-toggle {
  display: none;
}
.hamburger-container {
  position: absolute;
  margin-right: -15%;
  right: 10%;
  width: 50px;
  height: 60px;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  opacity: 0;
}
#hamburger-hitmarker {
  z-index: 99;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
#hamburger-hitmarker:active {
  cursor: default;
}
.hamburger {
  display: block;
  position: absolute;
  top: 30px;
  left: 10px;
  width: 30px;
  height: 2px;
  -webkit-transform-origin: center;
  transform-origin: center;
}
.hamburger,
.hamburger:before,
.hamburger:after {
  z-index: 98;
  background-color: #000;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
}
.hamburger:before,
.hamburger:after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
}
.hamburger:before {
  top: -10px;
  left: -30px;
}
.hamburger:after {
  bottom: -10px;
  left: 30px;
}

#hamburger-toggle:not(:checked) ~ .hamburger:before,
#hamburger-toggle:not(:checked) ~ .hamburger:after {
  width: 25px;
}
.hamburger-container:hover > #hamburger-toggle:not(:checked) ~ .hamburger {
  width: 20px;
}
.hamburger-container:hover > #hamburger-toggle:not(:checked) ~ .hamburger:before,
.hamburger-container:hover > #hamburger-toggle:not(:checked) ~ .hamburger:after {
  width: 30px;
}

@media (max-width: 800px) {
  .menu {
    opacity: 0 !important;
    pointer-events: none !important;
  }

  .hamburger-container {
    margin-right: 0;
    opacity: 1;
  }

  .hamburger {
    pointer-events: auto !important;
  }
  .hamburger, {
    left: 15px !important;
  }
  .hamburger:before,
  .hamburger:after {
    left: 0;
  }

  /* fullscreen optimization */

  #hamburger-toggle:checked  ~ .fullscreen-menu > .image {
  	left: -100% !important;
  }
  #hamburger-toggle:checked  ~ .fullscreen-menu > .sidebar {
    width: 100%;
  }
}

/* hamburger -> cross animation & visibility */

#hamburger-toggle:checked ~ .hamburger {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
#hamburger-toggle:checked ~ .hamburger:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  bottom: 0;
}
#hamburger-toggle:checked ~ .hamburger:before {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  top: 0;
}
/* fullscreen toggle */

#hamburger-toggle:checked ~ .hamburger ~ .fullscreen-menu {
  pointer-events: auto;
  background-color: rgba(0, 0, 0, .7);
}
#hamburger-toggle:checked ~ .hamburger ~ .fullscreen-menu > .image {
  left: 0;
}
#hamburger-toggle:checked ~ .hamburger ~ .fullscreen-menu > .sidebar {
  right: 0;
  opacity: 1;
}


/* fullscreen menu + all elements */

.fullscreen-menu {
	z-index: 2;
	position: fixed;
	width: 100vw;
	height: 100vh;
  height: 100dvh;
  top: 0;
  left: 0;
	pointer-events: none;
	background: rgba(0, 0, 0, 0);
	transition: background 0.5s ease;
}

/* fullscreen menu image */

.fullscreen-menu > .image {
	position: absolute;
	width: 50%;
	height: 100%;
	left: -100%;
	background-color: #e4e4e4;
	overflow: hidden;
	transition: .3s ease;
}
.fullscreen-menu > .image > .menu-image {
	z-index: 1;
	position: absolute;
	width: 110%;
	height: 110%;
	left: -5%;
	top: -5%;
	/*background-image: url('');*/
	background-size: auto 100%;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
	/*filter: grayscale(100%);*/
}
.fullscreen-menu > .image a p {
	z-index: 2;
	position: absolute;
	font-size: 12px;
	line-height: 12px;
	bottom: 10px;
	left: 20px;
	color: #fafafa;
	opacity: .8;
}
.fullscreen-menu > .image a:hover p {
	color: #fff;
	opacity: 1;
}
.fullscreen-menu > .image a:active p {
	color: #fafafa;
	opacity: .8;
}

/* fullscreen menu sidebar */

.fullscreen-menu > .sidebar {
	position: absolute;
	width: 50%;
	height: 100%;
	right: -100%;
	background-color: #fff;
	overflow: hidden;
  transition-property: opacity, right;
	transition: 0.3s ease;
  opacity: 0;
}
.fullscreen-menu > .sidebar > .sidebar-logo {
	z-index: 2;
	position: absolute;
	height: 75px;
	left: 50px;
	top: 20px;
}
.fullscreen-menu > .sidebar .line-break {
	position: absolute;
	width: 100px;
	height: 2px;
	left: 50px;
	top: 130px;
	margin: 0 auto;
	background-color: #000;
}
.fullscreen-menu > .sidebar > .sidebar-menu {
	position: relative;
	width: 100%;
	left: 50px;
	top: 160px;
}
.fullscreen-menu > .sidebar > .sidebar-menu a {
	display: block;
	position: relative;
	width: 100%;
	left: -50px;
	padding-left: 50px;
	top: 0;
	font-size: 50px;
	line-height: 80px;
	font-weight: 400;
	color: #000;
	text-decoration: none;
	overflow: hidden;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.fullscreen-menu > .sidebar > .sidebar-menu a:hover {
  color: #fff;
	background-color: #005718;
}
.fullscreen-menu > .sidebar > .sidebar-menu a > span {
	position: absolute;
	width: 10px;
	height: 10px;
	top: 35px;
	left: 20px;
	background-color: #005718;
	border-radius: 50%;
}
.fullscreen-menu > .sidebar > .sidebar-menu a:hover > span {
  background-color: #fff;
}
.fullscreen-menu > .sidebar .menu-info {
	position: absolute;
	width: 100%;
	bottom: 0;
  padding-bottom: 25px;
	border-bottom: 20px solid #005718;
}
.fullscreen-menu > .sidebar .menu-info .column-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
	width: 90%;
	margin-left: 40px;
	text-align: center;
}
.fullscreen-menu > .sidebar .menu-info .column-container .menu-column {
	position: relative;
	text-align: left;
  padding-right: 10px;
}
.fullscreen-menu > .sidebar .menu-info .column-container .menu-column h2 {
	position: relative;
	top: 0;
	left: 0;
	margin: 0 auto;
	font-size: 16px;
	line-height: 18px;
	color: #1f1f1f;
	font-weight: 600;
}
.fullscreen-menu > .sidebar .menu-info .column-container .menu-column .logo-line {
	position: absolute;
	width: 47px;
	height: 3px;
	left: 0;
	top: 43px;
	margin: 0 auto;
	background-color: #1f1f1f;
}
.fullscreen-menu > .sidebar .menu-info .column-container .menu-column a {
	position: relative;
  display: block;
	top: 0;
	left: 0;
	margin: 0 auto;
	font-size: 14px;
	line-height: 25px;
	color: #1f1f1f;
	font-weight: 300;
  text-decoration: underline;
}
.fullscreen-menu > .sidebar .menu-info .column-container .menu-column a:hover {
  color: #e3e3e3;
}
.fullscreen-menu > .sidebar .menu-info .column-container .menu-column a:active {
	text-decoration: none;
}
