
/* Content.js styling */

.content-wrapper {
  margin: 0 auto;
  width: 80%;
  left: 10%;
}
.content-block {
  display: flex;
  flex-direction: row;
}
.page-container > .content-wrapper:first-of-type > .content-block:first-of-type {
  margin-top: 100px;
}
.icons-block + .content-wrapper {
  margin-top: 50px;
}
.content-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 0px;
}
.content-column > * {
  margin: 0;
}
.content-title {
  color: #2d2d2d;
  font-weight: 800;
  font-size: 8vmin;
  font-size: min(20em, 8vmin);
}
@media (max-width: 950px) {
  .content-title {
    white-space: nowrap;
  }
}
.content-subtitle {
  color: #005718;
  font-size: calc(3vmin + 5px);
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 15px;
  white-space: nowrap;
}
.content-subtitle span {
  color: #fff;
  background-color: #005718;
  font-style: italic;
}
.content-paragraph {
  color: #959594;
  font-size: 16px;
  line-height: 30px;
  text-align: justify;
  margin-top: 15px;
  margin-bottom: 75px;
}
.content-images {
  position: relative;
  display: grid;
  align-content: center;
  place-items: center;
  height: 100%;
  margin-bottom: 60px;
  pointer-events: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.content-images img {
  position: absolute;
  width: 60%;
  aspect-ratio: 5 / 7;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 15px;
  transform: rotateZ(-2deg);
  box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.2);
  max-height: 100%; 
  animation-duration: 32s;
  animation-delay: none;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}
.content-images img.pos0 {
  z-index: 0;
  transform: rotateZ(-2deg);
  opacity: 1;
  animation-name: start-from-front;
}
.content-images img.pos1 {
  z-index: -1;
  transform: rotateZ(-6deg);
  opacity: .9;
  animation-name: start-from-middle;
}
.content-images img.pos2 {
  z-index: -2;
  transform: rotateZ(-10deg);
  opacity: .5;
  animation-name: start-from-back;
}

@keyframes start-from-front {
  0%, 32% {transform: rotateZ(-2deg) translateX(0%); opacity: 1; z-index: 0;}
  33% {transform: rotateZ(2deg) translateX(50%);}

  35%, 65% {transform: rotateZ(-10deg) translateX(0%); opacity: .5; z-index: -2;}
  66% {transform: rotateZ(-14deg) translateX(-50%);}

  68%, 97% {transform: rotateZ(-6deg) translateX(0%); opacity: .9; z-index: -1;}
  98% {transform: rotateZ(-10deg) translateX(-50%);}

  100% {transform: rotateZ(-2deg) translateX(0%); opacity: 1; z-index: 0;}
}

@keyframes start-from-middle {
  0%, 32% {transform: rotateZ(-6deg) translateX(0%); opacity: .9; z-index: -1;}
  33% {transform: rotateZ(-10deg) translateX(-50%);}

  35%, 65% {transform: rotateZ(-2deg) translateX(0%); opacity: 1; z-index: 0;}
  66% {transform: rotateZ(2deg) translateX(50%);}

  68%, 97% {transform: rotateZ(-10deg) translateX(0%); opacity: .5; z-index: -2;}
  98% {transform: rotateZ(-14deg) translateX(-50%);}

  100% {transform: rotateZ(-6deg) translateX(0%); opacity: .9; z-index: -1;}
}

@keyframes start-from-back {
  0%, 32% {transform: rotateZ(-10deg) translateX(0%); opacity: .5; z-index: -2;}
  33% {transform: rotateZ(-14deg) translateX(-50%);}

  35%, 65% {transform: rotateZ(-6deg) translateX(0%); opacity: .9; z-index: -1;}
  66% {transform: rotateZ(-10deg) translateX(-50%);}

  68%, 97% {transform: rotateZ(-2deg) translateX(0%); opacity: 1; z-index: 0;}
  98% {transform: rotateZ(2deg) translateX(50%);}

  100% {transform: rotateZ(-10deg) translateX(0%); opacity: .5; z-index: -2;}
}

@media (max-width: 950px) {
  .content-block {
    flex-direction: column;
  }
  .content-images,
  .content-images img {
    height: 400px;
    width: auto;
  }
}
